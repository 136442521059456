import { graphql, Link } from 'gatsby';
import get from 'lodash/get';
import React, { Component } from 'react';
import ArticlePreview from '../components/article-preview';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { FaLongArrowAltRight, FaLongArrowAltLeft } from 'react-icons/fa';

class Articles extends Component {
    handleTitle = (category) => {
        switch (category) {
            case 'thoughts-and-tips':
                return 'خواطر ونصائح (متجدد)';
            case 'family-and-educational':
                return 'مقالات عن العلاقات والتربية';
            case 'psychological':
                return 'مقالات نفسية';
            case 'nominations-for-books-movies-and-novels':
                return 'ترشيحات الكتب والافلام والروايات';
            case 'other':
                return 'مقالات أخرى';
            case 'english-articles':
                return 'English Articles';
            case 'my-journey-with-writing':
                return 'رحلتي مع الكتابة';
            case 'me-and-places':
                return 'أنا والأماكن';
            case 'short-story':
                return 'قصص مصوره';
            case 'opinion-articles-and-problems-mail':
                return 'مقالات الرأي وبريد المشكلات';
            default:
                return 'المقالات والخواطر';
        }
    };
    render() {
        const posts = get(this, 'props.data.allContentfulBlogPost.edges');
        const { category, currentPage, numPages } = this.props.pageContext;
        const isFirst = currentPage === 1;
        const isLast = currentPage === numPages;
        const prevPage = currentPage - 1 === 1 ? `/articles/${category}/` : (currentPage - 1).toString();
        const nextPage = (currentPage + 1).toString();
        return (
            <Layout location={this.props.location}>
                <Seo
                    title={`المقالات`}
                    keywords={[
                        'الصحه العامه',
                        'الصحه النفسيه',
                        'شغبطه بقلم د.هبه اللكاوي',
                        'shaghbatta',
                        'المقالات',
                        'مقالات عن الصحه النفسيه',
                    ]}
                />
                <div className="container my-5">
                    <h2 className="latest-heading py-5 m-0 text-center">
                        <span>{this.handleTitle(category)}</span>
                    </h2>
                    <div className="row" style={{ direction: category === 'english-articles' ? 'ltr' : 'rtl' }}>
                        {posts &&
                            posts.map(({ node }) => {
                                return (
                                    <article
                                        className="col-md-4 my-3"
                                        key={node.slug}
                                        data-sal="zoom-in"
                                        data-sal-delay="150"
                                        data-sal-easing="ease-in">
                                        <ArticlePreview article={node} />
                                    </article>
                                );
                            })}
                    </div>

                    <nav className="text-center d-flex justify-content-center mt-4" dir="rtl">
                        <ul className="pagination">
                            {!isFirst && (
                                <li className="page-item">
                                    <Link className="page-link" to={prevPage} rel="prev">
                                        الصفحة السابقة
                                        <FaLongArrowAltLeft className="mr-2" />
                                    </Link>
                                </li>
                            )}
                            {Array.from({ length: numPages }, (_, i) => (
                                <li className="page-item">
                                    <Link
                                        className="page-link"
                                        key={`pagination-number${i + 1}`}
                                        to={`/articles/${category}/${i === 0 ? '' : i + 1}`}>
                                        {i + 1}
                                    </Link>
                                </li>
                            ))}
                            {!isLast && (
                                <li className="page-item">
                                    <Link className="page-link" to={nextPage} rel="next">
                                        <FaLongArrowAltRight className="ml-2" />
                                        الصفحة التالية
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </nav>
                </div>
            </Layout>
        );
    }
}

export default Articles;
export const pageQuery = graphql`
    query BlogPostsByCategory($category: String!, $skip: Int!, $limit: Int!) {
        allContentfulBlogPost(
            sort: { fields: [publishDate], order: DESC }
            limit: $limit
            skip: $skip
            filter: { category: { eq: $category } }
        ) {
            edges {
                node {
                    title
                    slug
                    category
                    publishDate(formatString: "MMMM Do, YYYY")
                    description {
                        description
                    }
                    author {
                        name
                    }
                    heroImage {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    }
`;
